import * as React from "react"
import { Link } from "gatsby"

export default function Button( {
  children,
  to,
  activeClassName="active",
  partiallyActive,
  hasIcon,
  targetBlank = false,
  ...other }) {
 
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <Link
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
        {
          (hasIcon) 
            ?
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-1r">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 5.30316C0 5.15162 0.0602 5.00628 0.167357 4.89913C0.274514 4.79197 0.41985 4.73177 0.571392 4.73177H14.0482L10.4519 1.13657C10.3446 1.02928 10.2843 0.88376 10.2843 0.732026C10.2843 0.580292 10.3446 0.434772 10.4519 0.32748C10.5592 0.220188 10.7047 0.159912 10.8565 0.159912C11.0082 0.159912 11.1537 0.220188 11.261 0.32748L15.8321 4.89862C15.8853 4.95169 15.9276 5.01475 15.9564 5.08417C15.9852 5.15359 16 5.228 16 5.30316C16 5.37832 15.9852 5.45274 15.9564 5.52216C15.9276 5.59158 15.8853 5.65463 15.8321 5.70771L11.261 10.2788C11.1537 10.3861 11.0082 10.4464 10.8565 10.4464C10.7047 10.4464 10.5592 10.3861 10.4519 10.2788C10.3446 10.1716 10.2843 10.026 10.2843 9.8743C10.2843 9.72257 10.3446 9.57705 10.4519 9.46975L14.0482 5.87455H0.571392C0.41985 5.87455 0.274514 5.81436 0.167357 5.7072C0.0602 5.60004 0 5.45471 0 5.30316Z" fill="#66113F"/>
            </svg>
            :
            null
        }
      </Link>
    )
  }
  const target = targetBlank ? '_blank' : ""
  return (
    <a href={to} {...other} target={target}>
      {children}
      {
        (hasIcon) 
          ?
          <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-1r">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 5.30316C0 5.15162 0.0602 5.00628 0.167357 4.89913C0.274514 4.79197 0.41985 4.73177 0.571392 4.73177H14.0482L10.4519 1.13657C10.3446 1.02928 10.2843 0.88376 10.2843 0.732026C10.2843 0.580292 10.3446 0.434772 10.4519 0.32748C10.5592 0.220188 10.7047 0.159912 10.8565 0.159912C11.0082 0.159912 11.1537 0.220188 11.261 0.32748L15.8321 4.89862C15.8853 4.95169 15.9276 5.01475 15.9564 5.08417C15.9852 5.15359 16 5.228 16 5.30316C16 5.37832 15.9852 5.45274 15.9564 5.52216C15.9276 5.59158 15.8853 5.65463 15.8321 5.70771L11.261 10.2788C11.1537 10.3861 11.0082 10.4464 10.8565 10.4464C10.7047 10.4464 10.5592 10.3861 10.4519 10.2788C10.3446 10.1716 10.2843 10.026 10.2843 9.8743C10.2843 9.72257 10.3446 9.57705 10.4519 9.46975L14.0482 5.87455H0.571392C0.41985 5.87455 0.274514 5.81436 0.167357 5.7072C0.0602 5.60004 0 5.45471 0 5.30316Z" fill="#66113F"/>
          </svg>
          :
          null
      }
    </a>
  )
}
