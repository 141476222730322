import * as React from "react"
import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useStaticQuery, graphql } from "gatsby"
import Button from '../components/Button.js'

export default function HeaderNotification() {
  const location = useLocation()

  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: {eq: 3}) {
        paginaPoliticaFields {
          comunicacaoPoliticaCookie
        }
      }
    }
  `)

  return (
    <>
      <div className="fs-body tac bg-color-purple mb-0 p-05r gic-notification">
        <Button to="/grau-de-integracao-da-comunicacao" className="c-white" hasIcon>
          Avalie o Grau de Integração da Comunicação® (GIC) da sua empresa e obtenha insights estratégicos.
        </Button>
      </div>
      <CookieConsent
        disableStyles={true}
        buttonText="Aceitar"
        cookieName="cookie-consent"
        expires={150}
        containerClasses="notification d-flex align-items-center fs-body-small"
        buttonClasses="action"
        onAccept={() => { initializeAndTrack(location) }}
      >
        <p className="mb-0">{data.wpPage.paginaPoliticaFields.comunicacaoPoliticaCookie}</p>
      </CookieConsent>
    </>
  )
}