import * as React from "react"
import Button from '../components/Button.js'


const CommonFooter = () => {
  const date = new Date().getFullYear()

  return (
    <div className="bg-color-gray-1 copyright tac c-white fs-body-small">
      <p className="mb-0">Copyright© {date} | Todos os direitos são reservados à Comunicação Integrada. <Button to="/politica-privacidade" className="c-golden">Política de privacidade.</Button></p>
    </div>
  )
}

export default CommonFooter
